<template>
  <div>



    <svg v-if="!type" id="Layer_1" viewBox="0 0 187.83 73.09">
      <path
        id="Trazado_248"
        data-name="Trazado 248"
        class="cls-1"
        d="M19.34,64.62a36.17,36.17,0,0,0,19.59,5.71h-.12a33.8,33.8,0,0,0,8.4-1c.24-.11.6-.11.84-.23a37.64,37.64,0,0,0,12.72-6.18,32.39,32.39,0,0,0,9.58-12.35A32,32,0,0,0,70.65,26a28.58,28.58,0,0,0-3.37-5.83h0a23,23,0,0,0-9.15-6.88c-.24-.11-.36-.11-.6-.23a13.72,13.72,0,0,0-3.72-.93A22.21,22.21,0,0,0,49,11.94h0c-.48,0-.84.11-1.2.11A21.54,21.54,0,0,0,33,19.51a2.49,2.49,0,0,0,0,2.57,2.13,2.13,0,0,0,2.28.81,13.42,13.42,0,0,1,13.59,3.85,13,13,0,0,1-.8,18.65,14.59,14.59,0,0,1-8.64,3.5h-1.2a12.86,12.86,0,0,1-8.54-3.62c-7.1-6.52-7.49-18-3.42-26.11a20.3,20.3,0,0,1,1.19-2.1A26.06,26.06,0,0,1,39.62,7.51s1.44-.47,2.28-.7a1.94,1.94,0,0,0,1.56-2.1,2.18,2.18,0,0,0-2.05-2.1,15.29,15.29,0,0,0-3.24-.12C19,2.49,3.25,17.53,3.06,36.18A35.08,35.08,0,0,0,19.34,64.62M45.42,20.79c-2.88.94-5.65.24-6.25-1.63v-.11c-.49-1.75,1.31-3.85,4.07-4.78s5.28-.35,6.13,1.28c0,.12.12.23.12.35.61,1.86-1.19,4.08-4.07,4.89m9.12-.69c-.72.11-1.32-.24-1.32-.59a.43.43,0,0,1,.12-.35,1.27,1.27,0,0,1,1.08-.58,1.51,1.51,0,0,1,1.2.23c.12.12.12.24.24.35a1.7,1.7,0,0,1-1.32.94m-3.85-4.32v-.11c0-.94,1.2-1.64,2.64-1.64h.24a4.08,4.08,0,0,1,2.52,1.05.91.91,0,0,1,.25.94c-.12,1-1.32,1.74-3,1.63-1.44-.24-2.65-1-2.65-1.87"
      />
      <path
        class="cls-1"
        d="M88.23,57H85.38a3,3,0,0,1-3-3V20a3,3,0,0,1,3-3H99.91a12.11,12.11,0,0,1,9.39,3.77,13.22,13.22,0,0,1,3.46,9.3,12.8,12.8,0,0,1-3.43,9.17q-3.42,3.62-9.42,3.62H91.23V54A3,3,0,0,1,88.23,57Zm3-21.07h7.54a4.58,4.58,0,0,0,3.71-1.63A6.33,6.33,0,0,0,103.85,30a6.67,6.67,0,0,0-1.34-4.37A4.56,4.56,0,0,0,98.77,24H91.23Z"
      />
      <path
        class="cls-1"
        d="M142.08,27.7h2.51a3,3,0,0,1,3,3V54a3,3,0,0,1-3,3h-2.51a3,3,0,0,1-3-3V52.7a10.65,10.65,0,0,1-9.19,4.91,13.15,13.15,0,0,1-10.39-4.39,16,16,0,0,1-3.89-11,16,16,0,0,1,3.74-10.79q3.75-4.35,10.37-4.34a11.71,11.71,0,0,1,5.33,1.25,11.19,11.19,0,0,1,4,3.37v-1A3,3,0,0,1,142.08,27.7Zm-18,14.55a9.08,9.08,0,0,0,2,6.06,6.84,6.84,0,0,0,5.51,2.4,7.55,7.55,0,0,0,5.48-2.12,7.86,7.86,0,0,0,2.17-5.88,9.06,9.06,0,0,0-2.17-6.39A7.43,7.43,0,0,0,131.43,34a6.57,6.57,0,0,0-5.34,2.37A8.94,8.94,0,0,0,124.12,42.25Z"
      />
      <path
        class="cls-1"
        d="M168.43,46l6.23-16.39a3,3,0,0,1,2.8-1.93h2.68a3,3,0,0,1,2.74,4.21L166.59,68.56a3,3,0,0,1-2.74,1.79h-2.09A3,3,0,0,1,159,66.18l4.91-11.65-10.14-22.6a3,3,0,0,1,2.73-4.23h2.89a3,3,0,0,1,2.8,1.93Z"
      />
    </svg>
  
               
    <svg
      v-if="type"
      version="1.1"
      id="Layer_1"
   class="ml-1"
      x="0px"
      y="0px"
      viewBox="0 0 23.5 23.6"
      style="enable-background: new 0 0 23.5 23.6"
      xml:space="preserve"
    >
      <path
        class="laO"
        d="M5.4,21.6c2,1.3,4.3,2,6.6,2l0,0c1,0,1.9-0.1,2.9-0.4c0.1,0,0.2,0,0.3-0.1c1.6-0.4,3-1.2,4.3-2.2
	c1.4-1.1,2.5-2.6,3.2-4.3c1.1-2.7,1.2-5.8,0.1-8.5c-0.3-0.7-0.7-1.4-1.1-2l0,0c-0.8-1.1-1.9-1.9-3.1-2.4c-0.1,0-0.1-0.1-0.2-0.1
	c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5-0.1-1.1-0.1-1.6-0.1h0c-0.1,0-0.3,0-0.4,0c-2.7,0.3-4.4,1.8-5,2.6c-0.1,0.1-0.2,0.6,0,0.9
	C10.2,7,10.3,7,10.4,7.1c0.1,0,0.3,0.1,0.4,0c1.5-0.5,3.3-0.1,4.6,1.3c0.6,0.6,1,1.4,1.1,2.3c0.2,0.9,0.1,1.7-0.3,2.5
	c-0.3,0.8-0.9,1.5-1.6,2c-0.7,0.5-1.6,0.8-2.4,0.8h-0.4c-1.1,0-2.1-0.5-2.9-1.2c-2.4-2.3-2.5-6.2-1.1-9.1C8,5.6,8.1,5.3,8.2,5.1
	c0,0,1.4-2.4,4.1-3.3c0,0,0.5-0.2,0.8-0.2c0.3-0.1,0.6-0.4,0.5-0.7c0-0.4-0.2-0.6-0.7-0.7c-0.2-0.1-0.8,0-1.1,0
	C8.8,0,5.8,1.2,3.5,3.4C1.3,5.6,0,8.6,0,11.7c0,2,0.5,3.9,1.5,5.6C2.4,19.1,3.8,20.5,5.4,21.6L5.4,21.6z M14.2,6.4
	c-1,0.3-1.9,0.1-2.1-0.5c0,0,0,0,0,0c-0.2-0.6,0.5-1.3,1.4-1.6c0.9-0.3,1.8-0.1,2.1,0.4c0,0,0,0.1,0,0.1C15.8,5.4,15.2,6.1,14.2,6.4
	L14.2,6.4z M17.3,6.2c-0.2,0-0.4-0.1-0.5-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1C17.7,6,17.5,6.1,17.3,6.2z M16,4.7C16,4.6,16,4.6,16,4.7C16,4.3,16.4,4,16.9,4H17
	c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.5,0.6-1,0.6C16.4,5.3,16,5,16,4.7"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    type: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  mounted() {},
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.cls-1 {
  fill: #fff;
}
#Layer_1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.laO {
  fill: #ffffff;
}

</style>